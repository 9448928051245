import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import AboutContainer from 'components/about';
import Head from 'components/head';
import 'katex/dist/katex.min.css';

const Documentation = ({ data }) => (
  <Layout>
    <Head pageTitle={data.documentationJson.title} />
    <AboutContainer>
      <div
        dangerouslySetInnerHTML={{
          __html: data.documentationJson.content.childMarkdownRemark.html,
        }}
      />
    </AboutContainer>
  </Layout>
);

Documentation.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Documentation;

export const query = graphql`
  query DocumentationQuery {
    documentationJson {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
